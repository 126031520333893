import { createSlice } from "@reduxjs/toolkit";
import appApi from "../services/appApi";

const initialState = null;

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    logout: () => initialState,
    clearCart: (state) => {
      // Assuming your cart state is nested under user like: state.user.cart
      state.cart = 0; // Clear cart
      state.total = 0; // Reset total
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      appApi.endpoints.signup.matchFulfilled,
      (_, { payload }) => payload
    );
    builder.addMatcher(
      appApi.endpoints.login.matchFulfilled,
      (_, { payload }) => payload
    );
    builder.addMatcher(
      appApi.endpoints.addToCart.matchFulfilled,
      (_, { payload }) => payload
    );
    builder.addMatcher(
      appApi.endpoints.removeFromCart.matchFulfilled,
      (_, { payload }) => payload
    );
    builder.addMatcher(
      appApi.endpoints.increaseCartProduct.matchFulfilled,
      (_, { payload }) => payload
    );
    builder.addMatcher(
      appApi.endpoints.decreaseCartProduct.matchFulfilled,
      (_, { payload }) => payload
    );
  },
});

export const {
  logout,
  clearCart, // Include the clearCart action
  addToCart,
  removeFromCart,
  increaseCartProduct,
  decreaseCartProduct,
} = userSlice.actions;
export default userSlice.reducer;
