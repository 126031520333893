import React from "react";
import "../pages/styles/styles.css";
import { LinkContainer } from "react-router-bootstrap";
import { Card } from "react-bootstrap";

function ProductPreview({ _id, category, name, pictures, price, balance, country, showBalance = true, showCountry = true  }) {
  return (
    <LinkContainer
      to={`/product/${_id}`}
      style={{
        cursor: "pointer",
        background:"#333",
        margin: "10px",
        boxShadow: "0px 10px 20px 1px rgb(51 51 51 / 13%)",
        borderRadius: 0,
      }}
    >
      <Card style={{ width: "20rem", margin: "10px" }}>
        <Card.Img
          variant="top"
          className="product-preview-img"
          src={pictures[0].url}
          style={{
            objectFit: "cover",
            width: "100%",
            borderRadius: 0,
          }}
        />
        <Card.Body>
          <Card.Title className="cardText text-white">{name}</Card.Title>
          {showCountry && <p className="cardPara text-white m-0">{country}</p>}
          <p className="cardPara text-white m-0">Price: ${price}.00</p>
          {showBalance && <p className="cardPara text-white">Balance: ${balance}.00</p>}
        </Card.Body>
      </Card>
    </LinkContainer>
  );
}

export default ProductPreview;
