import React, { useEffect, useState } from "react";
// import { Redirect } from "react";
import {
  Row,
  Col,
  Container,
  Badge,
  ButtonGroup,
  Button,
  Modal,
  ModalBody
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import axios from "../axios";
import SimilarProduct from "../components/SimilarProduct";
import Loading from "../components/Loading";
import { useAddToCartMutation } from "../Redux/services/appApi";
import ToastMessage from "../components/ToastMessage";

function ProductPage() {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const [product, setProduct] = useState(null);
  const [similar, setSimilar] = useState(null);
  const [addToCart, { isSuccess }] = useAddToCartMutation();
  // const [isDeleted, setIsDeleted] = useState(false);
  const [showDeleteNotification, setShowDeleteNotification] = useState(false); // State to manage the visibility of the delete notification

  const handleDragStart = (e) => {
    e.preventDefault();
  };

  const handleAddToCart = (e) => {
    e.preventDefault();
    addToCart({
      userId: user._id,
      productId: id,
      price: product.price,
      image: product.pictures[0].url,
    });
  };

  const handleDeleteProduct = () => {
    axios
      .delete(`/products/del/${id}`)
      .then(() => {
        // setIsDeleted(true);
        setShowDeleteNotification(true); // Show delete notification after successful deletion
      })
      .catch((error) => {
        console.error("Error deleting product:", error);
      });
  };

  useEffect(() => {
    axios
      .get(`/products/${id}`)
      .then(({ data }) => {
        setProduct(data.product);
        setSimilar(data.similar);
      })
      .catch((error) => {
        console.error("Error fetching product:", error.response);
      });
  }, [id]);

  // if (isDeleted) {
  //   return <Redirect to="/" />; // Redirect to home route after deletion
  // }

  if (!product) return <Loading />;

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const images = product.pictures.map((pictures) => (
    <img
      className="product__carousel--image"
      src={pictures.url}
      onDragStart={handleDragStart}
      alt={pictures.name}
    />
  ));

  let similarProducts = [];
  if (similar) {
    similarProducts = similar.map((product, idx) => (
      <div className="item" data-value={idx} key={idx}>
        <SimilarProduct {...product} />
      </div>
    ));
  }

  return (
    <Container style={{ position: "relative", paddingTop: "10rem" }}>
      {showDeleteNotification && (
        <ModalBody>
        <Modal.Header closeButton>
          <Modal.Title>Product deleted successfully!</Modal.Title>
        </Modal.Header>
        </ModalBody>
      )}
      <Row>
        <Col lg={6}>
          <AliceCarousel
            mouseTracking
            items={images}
            controlsStrategy="alternate"
          />
        </Col>
        <Col lg={6} className="pt-4">
          <h1>{product.name}</h1>
          <p>
            <Badge bg="primary">{product.category}</Badge>
          </p>
          <p className="product__price"> ${product.price}</p>
          <p style={{ textAlign: "justify" }} className="py-3">
            <strong>Description:</strong> {product.description}
          </p>
          {user && !user.isAdmin && (
            <ButtonGroup style={{ width: "90%" }}>
            
              <Button size="lg" onClick={handleAddToCart}>
                Add to cart
              </Button>
            </ButtonGroup>
          )}

          {user && user.isAdmin && (
            <>
              <Button size="lg" onClick={handleDeleteProduct}>
                Delete Product
              </Button>
            </>
          )}
          {isSuccess && (
            <ToastMessage
              bg="info"
              title="Added to cart"
              body={`${product.name} is in your cart`}
            />
          )}
        </Col>
      </Row>
      <div className="my-4">
        <h2>Similar Products</h2>
        <div className="d-flex justify-content-center align-items-center flex-wrap mt-5">
          <AliceCarousel
            mouseTracking
            items={similarProducts}
            responsive={responsive}
            controlsStrategy="alternate"
          ></AliceCarousel>
        </div>
      </div>
    </Container>
  );
}

export default ProductPage;
