import React, { useState, useEffect } from "react";
import "../pages/styles/styles.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "../axios";
import { Alert, Container, Col, Modal, Button } from "react-bootstrap";
import {
  useIncreaseCartProductMutation,
  useDecreaseCartProductMutation,
  useRemoveFromCartMutation,
} from "../Redux/services/appApi";
import { clearCart } from "../Redux/features/userSlice";

function CartPage() {
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [showDeclinePopup, setShowDeclinePopup] = useState(false);
  // const [showEmptyCartAlert, setShowEmptyCartAlert] = useState(true);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [isDownloadButtonActive, setIsDownloadButtonActive] = useState(false);
  const user = useSelector((state) => state.user);
  const products = useSelector((state) => state.products);
  const userCartObj = user.cart;
  let cart = products.filter((product) => userCartObj[product._id] != null);

  const [increaseCart] = useIncreaseCartProductMutation();
  const [decreaseCart] = useDecreaseCartProductMutation();
  const [removeFromCart, { isLoading }] = useRemoveFromCartMutation();

  // views
  const [view, setView] = useState("cart");

  const handleConfirmOrderView = () => {
    setView("payment"); // Switch to the payment method view
  };

  // Wallet Address
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [btcWalletAddress, setBtcWalletAddress] = useState("");
  const [btcWalletQrCode, setBtcWalletQrCode] = useState("");
  const [ethWalletAddress, setEthWalletAddress] = useState("");
  const [ethWalletQrCode, setEthWalletQrCode] = useState("");
  const [usdtWalletAddress, setUsdtWalletAddress] = useState("");
  const [usdtWalletQrCode, setUsdtWalletQrCode] = useState("");

  const dispatch = useDispatch();

  function handleDecrease(product) {
    const quantity = user.cart[product._id];
    if (quantity <= 0) {
      return alert("Cannot proceed");
    } else {
      decreaseCart(product);
    }
  }
  function handleApprovePayment() {
    // setShowApprovePopup(false);
    const userId = user._id; // Assuming user is accessible here
    axios
      .post("users/haveMadePayment", { userId })
      .then((response) => {
        console.log(response.data.message); // Log success message
        // Handle success as needed
        setShowApprovePopup(true);

        const email = user.email; // Assuming user email is accessible

        axios
          .post("/send-email", { email })
          .then((emailResponse) => {
            console.log("Email sent successfully:", emailResponse.data);
            // Handle email success if needed
          })
          .catch((emailError) => {
            console.error("Error sending email:", emailError);
            // Handle email error
          });
      })

      .catch((error) => {
        console.error("Error marking payment as made:", error);
        // Handle error
        setShowErrorPopup(true);
      });
  }

  function handleDeclinePayment() {
    setShowDeclinePopup(true);
  }

  async function handleDownloadProduct(fileURL) {
    try {
      const userId = user._id;
      const paymentApprovedResponse = await axios.get(
        `users/paymentApproved/${userId}`
      );
      const haveMadePaymentResponse = await axios.get(
        `users/haveMadePayment/${userId}`
      );

      const paymentApproved = paymentApprovedResponse.data.paymentApproved;
      const haveMadePayment = haveMadePaymentResponse.data.haveMadePayment;

      if (paymentApproved && haveMadePayment) {
        // Open the file URL in a new tab
        const newTab = window.open(fileURL, "_blank");
        if (newTab) {
          // If window.open succeeded, wait for a short delay and then clear the cart
          setTimeout(() => {
            dispatch(clearCart());
          }, 1000);
        } else {
          // If window.open failed (likely due to a pop-up blocker), fallback to alternative method
          // For example, redirecting the current tab to the file URL
          window.location.href = fileURL;
          setTimeout(() => {
            dispatch(clearCart());
          }, 1000);
          // You may still want to clear the cart here, but it depends on your specific requirements
        }

        // Delay execution after 1 seconds
        setTimeout(async () => {
          try {
            // Send POST request to /paymentFalse endpoint
            await axios.post("users/paymentFalse", { userId });
            // Send POST request to /madePaymentFalse endpoint
            await axios.post("users/madePaymentFalse", { userId });

            // Additional actions after POST requests can be added here
            // dispatch(clearCart());
          } catch (error) {
            console.error("Error sending POST requests:", error);
            // Handle error
          }
        }, 1000);
      } else {
        // Show a message or handle as needed when conditions are not met
        console.log("Payment is not approved or payment not made");
      }
    } catch (error) {
      console.error("Error downloading product:", error);
      // Handle error
    }
  }

  const fetchWalletAddress = async () => {
    try {
      const response = await axios.get("/wallets/getWallet");
      if (!response.data) {
        throw new Error("Failed to fetch wallet addresses");
      }
      setBtcWalletAddress(response.data.btcWalletAddress);
      setBtcWalletQrCode(response.data.btcWalletQrCode);
      setEthWalletAddress(response.data.ethWalletAddress);
      setEthWalletQrCode(response.data.ethWalletQrCode);
      setUsdtWalletAddress(response.data.usdtWalletAddress);
      setUsdtWalletQrCode(response.data.usdtWalletQrCode);
    } catch (error) {
      console.error("Error fetching wallet addresses:", error);
    }
  };

  useEffect(() => {
    fetchWalletAddress();
  }, []);

  const handlePaymentMethodConfirmView = async () => {
    await fetchWalletAddress();
    setView("deposit");
    setTimeLeft(3600);
  };

  useEffect(() => {
    setIsDownloadButtonActive(false); // Initialize to false

    async function checkDownloadButtonStatus(fileURL) {
      try {
        const userId = user._id;
        const paymentApprovedResponse = await axios.get(
          `users/paymentApproved/${userId}`
        );
        const haveMadePaymentResponse = await axios.get(
          `users/haveMadePayment/${userId}`
        );

        const paymentApproved = paymentApprovedResponse.data.paymentApproved;
        const haveMadePayment = haveMadePaymentResponse.data.haveMadePayment;

        setIsDownloadButtonActive(paymentApproved && haveMadePayment);
      } catch (error) {
        console.error("Error checking download button status:", error);
        // Handle error
      }
    }

    checkDownloadButtonStatus();
  }, [user._id]);


  const [timeLeft, setTimeLeft] = useState(3600); // 1 hour in seconds

  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [timeLeft]);


  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <Container
      style={{ minHeight: "95vh", paddingTop: "130px" }}
      className="cart-container"
    >
      {/* Payment Popup */}
      <Modal show={showPaymentPopup} onHide={() => setShowPaymentPopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Make Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Send your payment to the following wallet address:
            <br />
            <div>
              <h5>BTC Wallet</h5>
              <p style={{ wordWrap: "break-word" }}>
                <strong>{btcWalletAddress}</strong>
              </p>
              {btcWalletQrCode && (
                <img src={btcWalletQrCode} alt="BTC Wallet QR Code" />
              )}
            </div>
            <div>
              <h5>ETH Wallet</h5>
              <p style={{ wordWrap: "break-word" }}>
                <strong>{ethWalletAddress}</strong>
              </p>
              {ethWalletQrCode && (
                <img src={ethWalletQrCode} alt="ETH Wallet QR Code" />
              )}
            </div>
            <div>
              <h5>Litecoin Wallet</h5>
              <p style={{ wordWrap: "break-word" }}>
                <strong>{usdtWalletAddress}</strong>
              </p>
              {usdtWalletQrCode && (
                <img src={usdtWalletQrCode} alt="USDT Wallet QR Code" />
              )}
            </div>
          </p>
          <Button variant="primary" onClick={() => handleApprovePayment()}>
            I have made payment
          </Button>
          <Button variant="danger" onClick={handleDeclinePayment}>
            Cancel Payment
          </Button>
        </Modal.Body>
      </Modal>
      {/* Approve Payment Popup */}
      <Modal show={showApprovePopup} onHide={() => setShowApprovePopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Waiting for Approval!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Please wait while the support team verify your payment details, you will recieve a confirmation message on your email.
        </Modal.Body>
      </Modal>
      {/* Decline Payment Popup */}
      <Modal show={showDeclinePopup} onHide={() => setShowDeclinePopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Declined</Modal.Title>
        </Modal.Header>
        <Modal.Body>The payment has been declined.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeclinePopup(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Access Popup */}
      <Modal show={showErrorPopup} onHide={() => setShowErrorPopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Network Error!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Try again after one minute</Modal.Body>
      </Modal>

      <>
        {view === "cart" ? (
          <div>
            <h3
              className="text-start ps-3 m-auto mb-3"
              style={{ maxWidth: "500px" }}
            >
              Checkout
            </h3>
            <div
              className="p-2 m-auto overflow-x-auto"
              style={{ maxWidth: "500px", maxHeight: "400px" }}
            >
              {cart.map((item) => (
                <div className="mb-2 border rounded p-3" key={item._id}>
                  <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex gap-3">
                      <img
                        src={item.pictures[0].url}
                        alt="itemPicture"
                        className="rounded"
                        style={{
                          width: 50,
                          height: 50,
                          objectFit: "cover",
                        }}
                      />
                      <div className="text-start">
                        <h4> {item.name}</h4>
                        <p
                          className="text-secondary mb-1"
                          style={{ fontSize: "12px" }}
                        >
                          {item.country}
                        </p>
                        <p
                          className="text-secondary"
                          style={{ fontSize: "12px" }}
                        >
                          Balance: ${item.balance}
                        </p>
                      </div>
                    </div>

                    <div>
                      {!isLoading && (
                        <i
                          className="fa fa-trash"
                          style={{ marginRight: "10", cursor: "pointer" }}
                          onClick={() =>
                            removeFromCart({
                              productId: item._id,
                              price: item.price,
                              userId: user._id,
                            })
                          }
                        ></i>
                      )}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between  fs-6">
                    <p className="mb-1">${item.price}.00</p>
                    <div>
                      <i
                        className="fa fa-minus-circle"
                        onClick={() =>
                          handleDecrease({
                            productId: item._id,
                            price: item.price,
                            userId: user._id,
                          })
                        }
                      ></i>
                      <span>{user.cart[item._id]}</span>
                      <i
                        className="fa fa-plus-circle"
                        onClick={() =>
                          increaseCart({
                            productId: item._id,
                            price: item.price,
                            userId: user._id,
                          })
                        }
                      ></i>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <p>Subtotal</p>
                    <p> ${item.price * user.cart[item._id]}.00</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>VAT</p>
                    <p> $0.00</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-2 p-3 m-auto" style={{ maxWidth: "500px" }}>
              <div className="d-flex justify-content-between align-items-center">
                <p>Total</p>
                <p> ${user.cart.total}.00</p>
              </div>
            </div>

            <div className="text-end m-auto" style={{ maxWidth: "500px" }}>
              <button
                className="rounded-1 p-2 bg-light me-2 text-dark"
                style={{ fontSize: "10px" }}
                onClick={handleConfirmOrderView}
              >
                CONFIRM ORDER
              </button>
            </div>
          </div>
        ) : view === "payment" ? (
          <div>
            <h3
              className="text-start ps-3 m-auto mb-3"
              style={{ maxWidth: "500px" }}
            >
              Payment Method
            </h3>
            <div className="m-auto p-3" style={{ maxWidth: "500px" }}>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex align-items-center gap-2 p-2 rounded text-dark bg-light p-2 rounded text-dark">
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="btc"
                    onChange={() => setSelectedPaymentMethod("btc")}
                  />
                  <p style={{ marginBottom: "0" }}>BTC</p>
                </div>
                <div className="d-flex align-items-center gap-2 p-2 rounded text-dark bg-light p-2 rounded text-dark">
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="eth"
                    onChange={() => setSelectedPaymentMethod("eth")}
                  />
                  <p style={{ marginBottom: "0" }}>ETH</p>
                </div>
                <div className="d-flex align-items-center gap-2 p-2 rounded text-dark bg-light p-2 rounded text-dark">
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="usdt"
                    onChange={() => setSelectedPaymentMethod("usdt")}
                  />
                  <p style={{ marginBottom: "0" }}>Litecoin </p>
                </div>
              </div>
            </div>

            <div className="text-end m-auto mt-4" style={{ maxWidth: "500px" }}>
              <button
                className="rounded-1 p-2 bg-light me-3 text-dark"
                style={{ fontSize: "11px" }}
                onClick={handlePaymentMethodConfirmView}
              >
                CONFIRM ORDER
              </button>
            </div>
          </div>
        ) : (
          <div className="m-auto" style={{ maxWidth: "500px" }}>
            <h3
              className="text-start ps-3 m-auto mb-3"
              style={{ maxWidth: "500px" }}
            >
              Send Deposit
            </h3>
            <div className="text-end">Time left: {formatTime(timeLeft)}</div>
            <div>
              {selectedPaymentMethod === "btc" && (
                <div className="border rounded ps-4 pe-4  ">
                  {btcWalletQrCode && (
                    <img
                      src={btcWalletQrCode}
                      alt="BTC Wallet QR Code"
                      style={{ width:"100%" }}
                    />
                  )}

                  <div className="text-start ">
                    <p
                      className="text-secondary"
                      style={{ marginBottom: "0" }}
                    >
                      Amount (BTC)
                    </p>
                    <p > ${user.cart.total}.00</p>
                  </div>
                  <div className="d-flex flex-column  text-start">
                    <p
                      className=" text-secondary"
                      style={{ marginBottom: "0" }}
                    >
                      Address
                    </p>
                    <p style={{ wordWrap: "break-word" }}>
                      {btcWalletAddress}
                    </p>
                  </div>
                </div>
              )}
              {selectedPaymentMethod === "eth" && (
                <div className="border rounded ps-4 pe-4">
                  {ethWalletQrCode && (
                    <img
                      src={ethWalletQrCode}
                      alt="ETH Wallet QR Code"
                      style={{ width:"100%" }}
                    />
                  )}

                  <div className="text-start ">
                    <p
                      className="text-secondary"
                      style={{ marginBottom: "0" }}
                    >
                      Amount (ETH)
                    </p>
                    <p> ${user.cart.total}.00</p>
                  </div>

                  <div className="d-flex flex-column   text-start">
                    <p
                      className="text-secondary"
                      style={{ marginBottom: "0" }}
                    >
                      Address
                    </p>
                    <p style={{ wordWrap: "break-word" }}>
                      {ethWalletAddress}
                    </p>
                  </div>
                </div>
              )}
              {selectedPaymentMethod === "usdt" && (
                <div className="border rounded ps-4 pe-4">
                  {usdtWalletQrCode && (
                    <img
                      src={usdtWalletQrCode}
                      alt="USDT Wallet QR Code"
                      style={{ width:"100%" }}
                    />
                  )}

                  <div className="text-start">
                    <p
                      className="text-secondary"
                      style={{ marginBottom: "0" }}
                    >
                      Amount (Litecoin)
                    </p>
                    <p> ${user.cart.total}.00</p>
                  </div>

                  <div className="d-flex flex-column text-start">
                    <p
                      className="text-secondary"
                      style={{ marginBottom: "0" }}
                    >
                      Address
                    </p>
                    <p style={{ wordWrap: "break-word" }}>
                      {usdtWalletAddress}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <Col md={7} className="m-auto ">
              {cart.length === 0 ? (
                <Alert variant="info">
                  Shopping cart is empty. Add products to your cart
                </Alert>
              ) : (
                <div className="pt-4 flexflex-column">
                  <Button
                    variant="primary"
                    onClick={() => handleApprovePayment()}
                    style={{width:"90%"}}
                    className="mb-3"
                  >
                    I have made payment
                  </Button>
                  <Button variant="danger" onClick={handleDeclinePayment}  style={{width:"90%"}}>
                    Cancel Payment
                  </Button>
                </div>
              )}

              <br />
              <Button
                variant="success"
                style={{ marginBottom: "20px", right: "20px", width:"90%" }}
                onClick={() => handleDownloadProduct(cart[0].fileURL)}
                disabled={!isDownloadButtonActive} // Disable download button if payment is not approved or download is in progress
              >
                Download Product
              </Button>
            </Col>
          </div>
        )}
      </>
    </Container>
  );
}

export default CartPage;
