import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../pages/styles/styles.css";

function ValidateOtp() {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleValidateOtp = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage("");
    setError("");
  
    try {
      console.log("Submitting OTP validation:", { 
        email, 
        otp, 
        newPasswordLength: newPassword.length 
      });
  
      const response = await fetch("https://hworld-backend.onrender.com/users/validateOtp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp, newPassword }),
      });
  
      const responseData = await response.json();
      console.log("OTP Validation Response:", responseData);
  
      if (!response.ok) {
        throw new Error(responseData.message || "Failed to validate OTP");
      }
  
      setMessage("Your password has been successfully reset.");
  
      // Redirect after 3 seconds
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (err) {
      console.error("OTP Validation Error:", err);
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <Container style={{ paddingTop: "60px", fontFamily: "Open Sans" }}>
      <Row>
        <Col md={6} className="login__form--container">
          <Form style={{ width: "90%" }} onSubmit={handleValidateOtp}>
            <h1>Reset Password</h1>
            <br />
            {message && <Alert variant="success">{message}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            <Form.Group>
              <Form.Label style={{ width: "100%", textAlign: "left" }}>
                Your Email
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label style={{ width: "100%", textAlign: "left" }}>
                OTP
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the OTP sent to your email"
                value={otp}
                required
                onChange={(e) => setOtp(e.target.value)}
              />
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label style={{ width: "100%", textAlign: "left" }}>
                New Password
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your new password"
                value={newPassword}
                required
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
            <br />
            <Form.Group>
              <Button
                type="submit"
                style={{ width: "100%" }}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Reset Password"}
              </Button>
            </Form.Group>
            <br />
            <p className="pt-3">
              Remembered your password? <a href="/login">Login here</a>
            </p>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ValidateOtp;
