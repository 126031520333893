import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useSelector } from "react-redux";
import axios from "../axios";


function Navigation() {
  const user = useSelector((state) => state.user || {}); // Ensure user is an object even if it's null


  const handleCategoryClick = (category) => {
    axios
      .get(`/products/category/${category}`)
      .then(({ data }) => {
        // Do something with the data if needed
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  };


  return (
    <Navbar expand="lg" className="navigation" style={{ width: "100%" }}>
      <Container className="optional flex-nowrap">
        <LinkContainer to="/" className="header-marg">
          <Navbar.Brand>
            <h4
              style={{ color: "white", fontFamily: "monospace", margin: "0" }}
              className="logo"
            >
              HWORLD
            </h4>
          </Navbar.Brand>
        </LinkContainer>

        {!user.isAdmin && (
          <> 
            <div className="specialList">
              <LinkContainer to="/category/Paypal log">
                <div className="agam"
                  onClick={() => handleCategoryClick("Paypal log")}
                >
                  Paypal Log
                </div>
              </LinkContainer>
              <LinkContainer to="/category/WAX software">
                <div className="agam"
                  onClick={() => handleCategoryClick("WAX software")}
                >
                  WAX Software
                </div>
              </LinkContainer>
              <LinkContainer to="/category/Wallet stealer">
                <div className="agam"
                  onClick={() => handleCategoryClick("Wallet stealer")}
                >
                  Wallet Stealer
                </div>
              </LinkContainer>
              <LinkContainer to="/category/Binance log">
                <div className="agam"
                  onClick={() => handleCategoryClick("Binance log")}
                >
                  Binance Log
                </div>
              </LinkContainer>
              <LinkContainer to="/category/Cashapp log">
                <div className="agam"
                  onClick={() => handleCategoryClick("Cashapp log")}
                >
                  Cashapp Log
                </div>
              </LinkContainer>
              <LinkContainer to="/category/Transfer service">
                <div className="agam"
                  onClick={() => handleCategoryClick("Transfer service")}
                >
                  Transfer Service
                </div>
              </LinkContainer>
            </div>
          </>
        )}


        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {user.email && (
              <NavDropdown
                title={`${user.email}`}
                id="basic-nav-dropdown"
                className="text-white"
              >
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
