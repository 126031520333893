import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faCartShopping,
  faBasketShopping,
  faTruck,
  faShield,
  faRightLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "../axios";
import "../pages/styles/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { updateProducts } from "../Redux/features/productSlice";
import ProductPreview from "../components/ProductPreview";

function Home() {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const lastProducts = products.slice(0, 23);
  const user = useSelector((state) => state.user);

  // const element = <FontAwesomeIcon icon={faCartShopping} />;
  const element2 = <FontAwesomeIcon icon={faBasketShopping} />;
  const element3 = (
    <FontAwesomeIcon
      icon={faTruck}
      style={{ fontSize: "30px", marginBottom: "10px" }}
    />
  );
  const element4 = (
    <FontAwesomeIcon
      icon={faShield}
      style={{ fontSize: "30px", marginBottom: "10px" }}
    />
  );
  const element5 = (
    <FontAwesomeIcon
      icon={faRightLeft}
      style={{ fontSize: "30px", marginBottom: "10px" }}
    />
  );

  useEffect(() => {
    axios
      .get("/products")
      .then(({ data }) => dispatch(updateProducts(data)))
      .catch((e) => {
        console.log(`Error is coming from ${e}`);
      });
  }, [dispatch]);

  const renderFeaturedProducts = () => {
    if (user) {
      return (
        <div className="homeContainer">
          <div className="featured-products-container container mt-4">
            <h2 style={{marginBottom:"40px"}}>Latest Log Product</h2>
            {/* last products here */}
            <div className="forGrid">
                  {lastProducts
                    .reverse()
                    .filter((product) => product.category !== "Wallet stealer")
                    .map((product, index) => (
                      <ProductPreview key={product._id} {...product} />
                    ))}
            </div>

            {/* Conditionally render another table for wallet logger category */}
            {lastProducts.some(
              (product) => product.category === "Wallet stealer"
            ) && (
              <div style={{marginTop:"100px"}}>
                <h2  style={{marginBottom:"40px"}}>Wallet Stealer</h2>
                <div className="forGrid">

                      {lastProducts
                        .reverse()
                        .filter(
                          (product) => product.category === "Wallet stealer"
                        )
                        .map((product, index) => (
                          <ProductPreview key={product._id} {...product} showBalance={false} showCountry={false} />
                          ))}
                </div>
              </div>
            )}
            <div>
              <Link
                className="seemore"
                to="/category/all"
                style={{ color: "black", backgroundColor: "red" }}
              >
                {" "}
                {/* Inline style for text color */}
                See more products {element2}
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="Home" style={{ paddingTop: "90px" }}>
      <div className="hero">
        <img
          src="https://res.cloudinary.com/dzzwvcapu/image/upload/v1713129271/tofunmithehuman/pfzi9dgiphb9g8l8f1a9.jpg"
          alt="banner"
        />
        <div className="contain">
          <h4>Best Available Products</h4>
          <br />
          <h1
            style={{
              textShadow: "2px 2px 4px rgb(186, 186, 186)",
              fontWeight: "600",
            }}
          >
            <span style={{ color: "rgb(123 174 54)" }}>Hack</span> World
          </h1>
          <br />
          <p>
            Welcome to Hack World Store. Please be aware that the products we
            sell on this website are only for use by those who are at least 18
            years old. If you want to continue making money as I do, keep this
            to yourself.
            <br />
            What we offer are resources to help you generate large amounts of
            money quickly. If you experience any problems with our products,
            please get in touch with support (you have up to 24 hours to return
            any defective items, and we'll replace them promptly).
          </p>
          <br />
          {/* <Link to="/" style={{color:"black"}}>SHOP NOW {element}</Link> */}
        </div>
      </div>

      {/* {renderFeaturedProducts()} */}

      <div className="why-choose-us">
        <h2
          className="text-center"
          style={{
            color: "#fff",
            fontSize: "30px",
            marginBottom: "50px",
            textShadow: "1px 1px 2px #00000080",
          }}
        >
          Why choose us?
        </h2>
        <div className="container">
          <div className="row">
            {element3}
            <h1>Fast Delivery</h1>
            <p style={{ color: "white" }}>
              Experience the convenience of lightning-fast delivery-. We
              understand that time is of the essence, and that is why we
              prioritize the efficiency in our delivery services.
            </p>
          </div>
          <div className="row">
            {element4}
            <h1>Secure Checkout</h1>
            <p style={{ color: "white" }}>
              Enjoy worry-free shopping with our secure checkout process. We
              prioritize the safety of your personal and financial information,
              ensuring that your online transactions are protected at every
              step.
            </p>
          </div>
          <div className="row">
            {element5}
            <h1>Easy Returns</h1>
            <p style={{ color: "white" }}>
              Experience hassle-free shopping with our easy return process. We
              understand that sometimes, a product might not meet your
              expectations, and that's why we've streamlined our return
              procedure.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
